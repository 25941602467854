import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowLeft, CircleCheckBig, XCircleIcon, BadgePlus, CheckCircle, XCircle, Clock } from "lucide-react";
import axios from "axios";
import Home from "./Home";
import { toast } from "sonner";
import { API_URL } from "../../config";
import { getBase64FromDataURL } from "../Cod/helper";
import CustomSelectInput from "./CustomSelectInput";
import AddAdhocDetails from "./AddAdhocDetails";

const initialFormState = {
  hub_id: "",
  hub_name: "",
};

const initialFormData = {
  date: new Date().toISOString().split("T")[0],
  runsheet_id: "",
  gps_device_number: "",
  opening_km: "",
  closing_km: "",
  gps_km: "",
  fresh_load: "",
  out_for_delivery: "",
  delivered: "",
  undelivered: "",
  out_for_pickup: "",
  pickup_done: "",
  pickup_not_done: "",
  vehicle_image: "",
  odometer_image: "",
  status: "",
  vehicle_number: "",
};

const TimelineItem = ({ title, status, message }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'complete':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'error':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500 animate-spin" />;
    }
  };

  return (
    <div className="flex items-center mb-4">
      <div className="flex-shrink-0 mr-2">
        {getStatusIcon()}
      </div>
      <div className="flex-grow">
        <p className={`text-sm ${status === 'complete' ? 'text-green-500' : status === 'error' ? 'text-red-500' : 'text-yellow-500'}`}>
          {title}
        </p>
        {message && status === 'error' && (
          <p className="text-xs text-red-400 mt-1">{message}</p>
        )}
      </div>
    </div>
  );
};

const Timeline = ({ children }) => {
  return (
    <div className="border-l-2 border-gray-700 pl-4">
      {children}
    </div>
  );
};

const VendorVehicle = () => {
  const BASE_URL = API_URL;
  const [searchParams] = useSearchParams();
  const [reviewInfo, setReviewInfo] = useState({
    employee_code: "",
    hub_name: "",
    name: "",
  });
  const [, setFormDataHubsetup] = useState(initialFormState);
  const [hubs, setHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error,] = useState(null);
  const [reviewErrors, setReviewErrors] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [hasGpsDevice, setHasGpsDevice] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [vendorId, setVendorId] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [, setVehicleType] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState([]);
  const [closingData, setClosingData] = useState(null);
  const [selectedVehicleIdForClosingForm, setSelectedVehicleIdForClosingForm] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [isVendorDetailOpen, setIsVendorDetailOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  // console.log("hasGpsDevice",hasGpsDevice)

  // Find the selected vehicle data
  const selectedVehicleDataForCloseForm = closingData?.find((item) => {
    console.log("Checking vehicle_id:", item.vehicle_id, selectedVehicleIdForClosingForm);
    return item.id === parseInt(selectedVehicleIdForClosingForm);
  });

  // console.log("selectedVehicleDataForCloseForm", selectedVehicleDataForCloseForm)

  const handleVehicleChangeForCloseForm = (e) => {
    setSelectedVehicleIdForClosingForm(e.target.value);
  };

  // const resetForm = () => {
  //   setFormData(initialFormData);
  //   setReviewErrors({});
  //   setHasGpsDevice(false);
  //   setTimelineItems([]);
  //   setCurrentStep(1);
  //   setIsOpen(false);
  //   setVehicleNumber("")
  // }
  const resetForm = () => {
    setFormData(initialFormData);
    setReviewInfo({
      employee_code: "",
      hub_name: "",
      name: "",
      vendor_name: "",
      vendor_type: "",
      vehicle_type: "",
      vehicle_number: ""
    });
    setReviewErrors({});
    setHasGpsDevice(false);
    setTimelineItems([]);
    setCurrentStep(1);
    setIsOpen(false);
    setVehicleNumber("");
    setVendorId([]);
    setVendorName([]);
    setVehicleId("");
    setSelectedVehicleIdForClosingForm("");
    setClosingData(null);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const id = searchParams.get("id");
      if (id) {
        try {
          const response = await axios.get(`${BASE_URL}/employee/${id}`, {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          });
          const userData = response.data.data;
          console.log("userData", userData);
          setReviewInfo((prevState) => ({
            ...prevState,
            employee_code: userData?.id || "",
            name: userData?.name,
          }));
          setFormDataHubsetup((prevState) => ({
            ...prevState,
            hub_id: userData?.id || "",
            name: userData?.name,
          }));
          let designation = userData?.designation;
          let hubRole = "";

          if (!designation || designation === "Hub Incharge")
            hubRole = "hubsInCharge";
          else if (designation === "Level 1") hubRole = "hubsLevel1";
          else hubRole = "hubsLevel2";

          // let hubIds = userData[hubRole]
          console.log(userData[hubRole], "--- user data ---");
          setHubs(userData[hubRole]);
        } catch (err) {
          toast.error("Failed to fetch Employee data. Please try again.");
          console.error("Error fetching user data:", err);
        }
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [searchParams, BASE_URL]);

  const validateForm = () => {
    const errors = {};
    // Step 1 validation
    if (currentStep === 1 && formData.status === 'opening') {
      if (!reviewInfo.hub_name || reviewInfo.hub_name.trim() === "") {
        errors.hub_name = "Hub Name is required.";
      }
      // Validate vendor_name
      if (!reviewInfo.vendor_name || reviewInfo.vendor_name === "") {
        errors.vendor_name = "Vendor Name is required.";
      }
      // Validate vehicle_type
      if (!reviewInfo.vehicle_type || reviewInfo.vehicle_type === "") {
        errors.vehicle_type = "Vehicle Type is required.";
      }
      // Validate vehicle_type
      if (!reviewInfo.vendor_type || reviewInfo.vendor_type === "") {
        errors.vendor_type = "Vendor Type is required.";
      }
      // Validate vehicle_number
      if (!reviewInfo.vehicle_number || reviewInfo.vehicle_number === "") {
        errors.vehicle_number = "Vehicle Number is required.";
      }
      // Validate other fields
      if (!formData.status) {
        errors.status = "Form type is required.";
      }
      // Validate other fields
      if (!formData.date) {
        errors.date = "Date is required.";
      }
    } else if (currentStep === 1 && formData.status === 'closing') {
      if (!selectedVehicleDataForCloseForm?.runsheet_id) {
        if (!formData.runsheet_id) {
          errors.runsheet_id = "Runsheet Id is required.";
        }
      }
      if (!selectedVehicleIdForClosingForm || selectedVehicleIdForClosingForm === "") {
        errors.vehicle_number = "Vehicle Number is required.";
      }
    }


    if(formData.status === 'opening' && currentStep === 1 && hasGpsDevice === false){
      if(!formData.gps_device_number){
        errors.gps_device_number = "GPS Device number is required.";
      } 
    }

    // Step 2 validation - Images and Measurements
  if (currentStep === 1) {
    // Image validation for opening form with GPS device
    if (formData.status === 'opening' && hasGpsDevice === true) {
      // Validate vehicle image
      if (!formData.vehicle_image?.length) {
        errors.vehicle_image = "Vehicle Image is required.";
      }
      
      // Validate odometer image
      if (!formData.odometer_image?.length) {
        errors.odometer_image = "Odometer Image is required.";
      }
      
      // Validate opening kilometer reading
      if (!formData.opening_km) {
        errors.opening_km = "Opening Km is required.";
      }
    }
    
    // Image validation for closing form without GPS device
    if (formData.status === 'closing' && !selectedVehicleDataForCloseForm?.gps_device_number) {
      // Validate vehicle image
      if (!formData.vehicle_image?.length) {
        errors.vehicle_image = "Vehicle Image is required.";
      }
      
      // Validate odometer image
      if (!formData.odometer_image?.length) {
        errors.odometer_image = "Odometer Image is required.";
      }
      
      // Validate closing kilometer reading
      if (!formData.closing_km) {
        errors.closing_km = "Closing Km is required.";
      } else if (formData.closing_km <= formData.opening_km) {
        errors.closing_km = "Closing Km should be greater than Opening Km.";
      }
    }
  }

  if (formData.status === 'closing' && selectedVehicleDataForCloseForm?.gps_device_number) {
    if (!formData.gps_km) {
      errors.gps_km = "GPS Km is required.";
    } 
  }


    // Step 3 validation
    // else if (currentStep === 3) {
    //   if (!selectedVehicleDataForCloseForm.fresh_load) {
    //     errors.fresh_load = "Fresh Load is required.";
    //   } else if (!selectedVehicleDataForCloseForm.out_for_delivery) {
    //     errors.out_for_delivery = "Out for Delivery is required.";
    //   } else if (!selectedVehicleDataForCloseForm.delivered) {
    //     errors.delivered = "Delivered is required.";
    //   } else if (!selectedVehicleDataForCloseForm.undelivered) {
    //     errors.undelivered = "Undelivered is required.";
    //   } else if (!selectedVehicleDataForCloseForm.out_for_pickup) {
    //     errors.out_for_pickup = "Out for Pickup is required.";
    //   } else if (!selectedVehicleDataForCloseForm.pickup_done) {
    //     errors.pickup_done = "Pickup Done is required.";
    //   } else if (!selectedVehicleDataForCloseForm.pickup_not_done) {
    //     errors.pickup_not_done = "Pickup Not Done is required.";
    //   }
    // }
    // Set the error state
    setReviewErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleNextClick = async () => {
    // Validate the form before proceeding
    if (!validateForm()) {
      console.log("validateForm", reviewErrors)
      return;
    }
    // setIsLoading(true);
    setTimelineItems([]);
    try {
      // Proceed to the next step if the form is valid
      if (formData.status === "opening" && currentStep === 1) {
        setTimelineItems(prev => [...prev, { title: 'Creating Trip', status: 'in-progress' }]);
        const response = await CreateTripForVendorVehicle(vendorId);
        console.log("response", response)
        setTimelineItems(prev => prev.map(item =>
          item.title === 'Creating Trip' ? { ...item, status: 'complete' } : item
        ));
        if (response) {
          setTimelineItems(prev => [
            ...prev,
            { title: 'Trip Created Successfully', status: 'complete' }
          ]);
          setCurrentStep(2);
        } else {
          setTimelineItems(prev => [
            ...prev,
            { title: 'Failed to Create Trip', status: 'error' }
          ]);
          toast.error("Failed to create trip. Please try again.");
        }
      } else if (formData.status === "closing" && currentStep === 2) {
        setTimelineItems(prev => [...prev, { title: 'Creating Trip', status: 'in-progress' }]);
        const response = await CreateTripForVendorVehicle(vendorId);
        console.log("response", response)
        // setCurrentStep(currentStep + 1);
        if (response) {
          setTimelineItems(prev => [
            ...prev,
            { title: 'Trip Created Successfully', status: 'complete' }
          ]);
          // setCurrentStep(4);
          setCurrentStep(currentStep + 1);

        } else {
          setTimelineItems(prev => [
            ...prev,
            { title: 'Failed to Create Trip', status: 'error' }
          ]);
          toast.error("Failed to create trip. Please try again.");
        }
      }
      else {
        if (currentStep === 2 && formData.status === "opening") {
          // If currentStep is 2 and status is opening, skip to step 4
          console.log("currentStep", currentStep)
          setCurrentStep(4);
        } else if (currentStep === 3 && formData.status === "closing") {
          setCurrentStep(currentStep + 1);
          // setCurrentStep(4);
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    } catch (error) {
      console.error("CreateTripForVendorVehicle failed:", error);
      setTimelineItems(prev => [
        ...prev,
        { 
          title: 'Error Creating Trip', 
          status: 'error',
          message: error.message || 'An unexpected error occurred'
        }
      ]);
      // setTimelineItems(prev => [...prev, { title: 'Error while creating Trip', status: 'failed' }]);
      toast.error("Failed to create trip. Please try again.");
    }
    // finally {
    //   setIsLoading(false); // Stop loading after the async operation completes
    // }
  };

  //  handleInputChange function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the specific form field
    }));
  };

  const handleReviewInfoChange = (event) => {
    const { name, value } = event.target;
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleStatusChange = async (e) => {
    const { value } = e.target;
    setFormData((prevReviewInfo) => ({
      ...prevReviewInfo,
      status: value,
    }));
    if (value === 'opening') {
      fetchDataForVendorIdAndVehicleId(reviewInfo.hub_name);
    }

    console.log("reviewInfo.hub_name", value)
    const id = reviewInfo.hub_name;
    if (value === "closing") {
      // API call when status is "closing"
      try {
        const response = await axios.get(`${BASE_URL}/trip/open/${id}`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });
        const closingData = response.data.data;
        setClosingData(response.data.data);
        console.log("Closing Data:", closingData);

        // Populate the form with closing data
        setFormData((prevFormData) => ({
          ...prevFormData,
          closing_km: closingData.closing_km || "",
          gps_km: closingData.gps_km || "",
          fresh_load: closingData.fresh_load || "",
          out_for_delivery: closingData.out_for_delivery || "",
          delivered: closingData.delivered || "",
          undelivered: closingData.undelivered || "",
          out_for_pickup: closingData.out_for_pickup || "",
          pickup_done: closingData.pickup_done || "",
          pickup_not_done: closingData.pickup_not_done || "",
        }));
      } catch (err) {
        console.error("Failed to fetch data for closing:", err);
      }
    }
  };

  const fetchDataForVendorIdAndVehicleId = async (id, type) => {
    console.log("reviewInfo.vendor_type", id, type);
    try {
      const response = await axios.get(`${BASE_URL}/vendor/hub-vendors/${id}`, {
        // ?type=${type}
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      });
      const Data = response.data.data;
      console.log("Data-fetchDataForVendorIdAndVehicleId", Data);
      setVendors(Data)
    } catch (err) {
      toast.error("Failed to fetch VendorId And VehicleId. Please try again.");
      console.error("Error fetching user data:", err);
    }
  };

  const fetchDataVehicleData = async (hubId, vendorId) => {
    console.log("fetchDataVehicleData", vendorId, hubId);
    try {
      const response = await axios.get(`${BASE_URL}/vendor/hub-vehicles?hub_id=${hubId}&vendor_id=${vendorId}`, {
        // ?type=${type}
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      });
      const Data = response.data.data;
      console.log("Data-fetchDataForAll", Data);
      setVehicleData(Data)
      const vehicleType = Data?.map((el) => el.type)
      console.log("vehicleType", vehicleType)
      setVehicleType(vehicleType)
    } catch (err) {
      toast.error("Failed to fetch VendorId And VehicleId. Please try again.");
      console.error("Error fetching user data:", err);
    }
  };

  const handleVendorNameChange = (event) => {
    const { name, value } = event.target;
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    console.log("value-vendor-id", value);
    let selected_vendors_id = vendors.filter((v) => v.id === value);
    console.log(selected_vendors_id, 'selected_vendors');
    setVendorId(value)
    fetchDataVehicleData(reviewInfo.hub_name, value)
  };

  const handleVendorTypeChange = (event) => {
    const { name, value } = event.target;
    console.log("value-selectrd-venodr", value);
    let selected_vendors = vendors.filter((v) => v.type.trim() === value.trim());
    console.log(selected_vendors, 'selected_vendors');
    // Update state with names and IDs of selected vendors
    const vendorData = selected_vendors.map((item) => ({
      name: item.name,
      id: item.id
    }));
    console.log("vendorData-428", vendorData)
    setVendorName(vendorData)
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  }

  const handleVehicleTypeChange = (event) => {
    const { name, value } = event.target;
    console.log(value, 'value-00')
    // console.log(reviewInfo, 'reviewInfo')
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    setVehicleId(value)
    console.log("vehicleData", vehicleData)
    // Get vehicle numbers for specified types
    console.log(value, 'value')
    const validType = vehicleData.findIndex((v) => v.id === parseInt(value));
    console.log(validType, 'valid type index')
    const vehicleNumbers = vehicleData[validType]?.vehicles?.map(v => v?.vehicle_number);  // Extract vehicle numbers
    console.log("vehiclesNumbers", vehicleNumbers);
    setVehicleNumber(vehicleNumbers)
  };

  const handleVehicleNumberChange = async (event) => {
    // console.log(value)
    const { name, value } = event.target;
    console.log("251", name, value)
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  }

  const CreateTripForVendorVehicle = async () => {
    // console.log("status",formData.status)
    const payload = {
      vendor_id: vendorId || undefined,
      vehicle_id: parseInt(vehicleId) || selectedVehicleDataForCloseForm?.id || undefined,
      vehicle_number: reviewInfo.vehicle_number || undefined,
      hub_id: reviewInfo.hub_name || undefined,
      date: formData.date || selectedVehicleDataForCloseForm?.date || undefined,
      runsheet_id: formData.runsheet_id || selectedVehicleDataForCloseForm?.runsheet_id || undefined,
      gps_device_number: formData.gps_device_number || selectedVehicleDataForCloseForm?.gps_device_number || undefined,
      opening_km: parseInt(formData.opening_km) || selectedVehicleDataForCloseForm?.opening_km || undefined,
      closing_km: parseInt(formData.closing_km) || undefined,
      gps_km: parseInt(formData.gps_km) || selectedVehicleDataForCloseForm?.gps_km || undefined,
      fresh_load: parseInt(formData.fresh_load) || selectedVehicleDataForCloseForm?.fresh_load || 0,
      out_for_delivery: parseInt(formData.out_for_delivery) || selectedVehicleDataForCloseForm?.out_for_delivery || 0,
      delivered: parseInt(formData.delivered) || selectedVehicleDataForCloseForm?.delivered || 0,
      undelivered: parseInt(formData.undelivered) || selectedVehicleDataForCloseForm?.undelivered || 0,
      out_for_pickup: parseInt(formData.out_for_pickup) || selectedVehicleDataForCloseForm?.out_for_delivery || 0,
      pickup_done: parseInt(formData.pickup_done) || selectedVehicleDataForCloseForm?.pickup_done || 0,
      pickup_not_done: parseInt(formData.pickup_not_done) || selectedVehicleDataForCloseForm?.pickup_not_done || 0,
      vehicle_image: getBase64FromDataURL(formData.vehicle_image) || 0,
      odometer_image: getBase64FromDataURL(formData.odometer_image) || 0,
    };
    // Remove fields with undefined values from the payload
    const filteredPayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v !== undefined));
    console.log("payload", payload)
    try {
      let response;
      if (formData.status === "opening") {
        const response = await axios.post(
          `${BASE_URL}/trip/opening`, // URL for opening
          payload, // FormData as payload
          {
            headers: {
              "ngrok-skip-browser-warning": "true", // Add headers here
            },
          }
        );

        // Handle the response for opening as needed
        console.log("Opening response:", response.data);
        const Data = response.data.data;
        console.log("Opening response-vendor-Data", Data);
        // setVendor(Data); // Assuming setVendor is a state setter function
        return Data;

      } else if (formData.status === "closing") {
        const response = await axios.post(
          `${BASE_URL}/trip/closing/${selectedVehicleIdForClosingForm}`, // URL for closing
          filteredPayload, // FormData as payload
          {
            headers: {
              "ngrok-skip-browser-warning": "true", // Add headers here
            },
          }
        );

        // Handle the response for closing as needed
        console.log("Closing response:", response.data);
        const Data = response.data.data;
        console.log("Closing response-vendor-Data", Data);
        // setVendor(Data); // Assuming setVendor is a state setter function
        return Data;
      }
      return response;
    } catch (err) {
      toast.error("Failed to fetch VendorId And VehicleId. Please try again.");
      console.error("Error fetching user data:", err);
    }
  };

  const handleCapturedImage = (image) => {
    console.log("Image received from Home:", image);
    setFormData((prevFormData) => ({
      ...prevFormData,
      vehicle_image: image, // Store the image as data URL
    }));

    console.log("Image captured:", image);
  };

  const handleCapturedOdometerImage = (odometer_image) => {
    console.log("Image received from Home:", odometer_image);
    setFormData((prevFormData) => ({
      ...prevFormData,
      odometer_image: odometer_image, // Store the image as data URL
    }));

    console.log("Image odometer captured:", odometer_image);
  };

  const handleOpenVendorDetailsModalModal = () => {
    setIsVendorDetailOpen(true)
  }

  const handleOpenModal = () => {
    setIsOpen(true);
  }

  const handleRightClick = async (payload) => {
    console.log("payload-583", payload)
    // Validate payload and required fields
    if (!payload || !payload.vehicle_number || !payload.vehicle_image || !payload.rc_image) {
      toast.error("Please provide all vehicle details!");
      return;
  }
    // Find the ID where vehicle_number is null
    const Id = vehicleId
    const vendor_id = vendorId
    // console.log("vehicleNumber", Id)
    // console.log("vendor-id", vendor_id)
    // console.log("payload", payload)
    const payloadData = {
      vendor_id: vendor_id,
      vehicle_type_id: Id.toString(),
      vehicle_number: payload.vehicle_number,
      vehicle_image: payload.vehicle_image,
      rc_image: payload.rc_image,
    }
    try {

      const response = await axios.put(`${BASE_URL}/vendor/vehicle`,
        payloadData,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });
      const Data = response.data.data;
      console.log("Update vehicle number Data:", Data);
      // Assuming the new vehicle number is in Data.vehicle_number
      if (Data && Data.vehicle_number) {
        console.log("VehicleNumber", vehicleNumber)
        console.log("Data.vehicle_number", Data.vehicle_number)
        setVehicleNumber((prevVehicleNumbers) => {
          console.log("Previous vehicle numbers:", prevVehicleNumbers);
          return [
            ...prevVehicleNumbers, // Spread the existing vehicle numbers
            Data.vehicle_number, // Add the new vehicle number
          ];
        });
      }
      toast.success("vehicle details added successfully")
      resetForm();
      setIsOpen(false)
    } catch (err) {
      console.log(err)
      toast.error("vehicle Number is already present!")
    }
  };

  const renderReviewInformation = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <p
          className="text-sm font-medium text-gray-100 opacity-65"
          style={{ fontSize: "0.75rem" }}
        >
          Step 1 of 2
        </p>
        Review Your Information
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : (
        <>
          {error && <p className="text-yellow-500 mb-4">{error}</p>}
          <div className="space-y-4 mb-6">
            <div>
              <label
                htmlFor="hub_name"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Hub Id
              </label>
              {hubs && hubs.length > 0 ? (
                <select
                  id="hub_name"
                  name="hub_name"
                  value={reviewInfo.hub_name}
                  onChange={handleReviewInfoChange}
                  className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                >
                  <option value="">Select a hub</option>
                  {hubs.map((hub) => (
                    <option key={hub.id} value={hub.id}>
                      {`${hub.location} (${hub.state} - ${hub.zone})`}
                    </option>
                  ))}
                </select>
              ) : (
                <div
                  className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
                  role="alert"
                >
                  <p className="font-bold">No Hubs Assigned</p>
                  <p>
                    There are no hubs attached to your name. Please contact your
                    administrator for assistance.
                  </p>
                </div>
              )}
              {reviewErrors["hub_name"] && (
                <p className="mt-1 text-sm text-red-500">
                  {reviewErrors["hub_name"]}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Form Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleStatusChange}
                // className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white ${!reviewInfo.hub_name ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                disabled={!reviewInfo.hub_name}
              >
                <option value="">Select Status</option>
                <option value="opening">Opening Form</option>
                <option value="closing">Closing Form</option>
              </select>
              {reviewErrors.status && (
                <p className="text-red-500">{reviewErrors.status}</p>
              )}
            </div>

            {formData.status === "opening" && (
              <>
                <div>
                  <label
                    htmlFor="vendor_type"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Vendor Type
                  </label>
                  <select
                    id="vendor_type"
                    name="vendor_type"
                    value={reviewInfo.vendor_type}
                    onChange={handleVendorTypeChange}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vendor Type</option>
                    <option value='fixed'>FIXED</option>
                    <option value='adhoc'>ADHOC</option>
                  </select>
                  {reviewErrors.vendor_type && (
                    <p className="text-red-500">{reviewErrors.vendor_type}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="vendor_name"
                    className="flex text-sm font-medium text-gray-300 mb-2 justify-between"
                  >
                    Vendor Name
                    {reviewInfo.vendor_type === 'adhoc' && (
                      <BadgePlus size={17}
                        onClick={handleOpenVendorDetailsModalModal}
                      />
                    )}
                  </label>
                  <select
                    id="vendor_name"
                    name="vendor_name"
                    value={reviewInfo.vendor_name}
                    onChange={handleVendorNameChange}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vendor</option>
                    {vendorName?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {`${item.name}`}
                      </option>
                    ))}
                  </select>
                  {reviewErrors.vendor_name && (
                    <p className="text-red-500">{reviewErrors.vendor_name}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="vehicle_type"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Vehicle Type
                  </label>
                  <select
                    id="vehicle_type"
                    name="vehicle_type"
                    value={reviewInfo.vehicle_type}
                    onChange={handleVehicleTypeChange}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vehicle Type</option>
                    {vehicleData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {`${item.type}`}
                      </option>
                    ))}
                  </select>
                  {reviewErrors.vehicle_type && (
                    <p className="text-red-500">{reviewErrors.vehicle_type}</p>
                  )}
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="vehicle_number"
                    className="flex text-sm font-medium text-gray-300 mb-2 justify-between"
                  >
                    Vehicle Number
                    <BadgePlus size={17}
                      onClick={handleOpenModal}
                    />
                  </label>
                  <div className="flex items-center border rounded-md shadow-sm bg-[#1F1F1F]">
                    <select
                      id="vehicle_number"
                      name="vehicle_number"
                      value={reviewInfo.vehicle_number}
                      onChange={handleVehicleNumberChange}
                      className="block w-full bg-transparent border-none text-white p-2 cursor-pointer appearance-none"
                    >
                      <option value="">Select a Vehicle</option>
                      {vehicleNumber?.length > 0 ? (
                        vehicleNumber?.map((item) => (
                          <option key={item} value={item}>
                            {/* {item} */}
                            {item.slice(0, -2)} 
                          </option>
                        ))
                      ) : (
                        <option key="" value="">
                          No Vehicle Number
                        </option>
                      )}
                    </select>
                  </div>
                  {reviewErrors.vehicle_number && (
                    <p className="text-red-500">{reviewErrors.vehicle_number}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    readOnly
                    // onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {reviewErrors.date && (
                    <p className="text-red-500">{reviewErrors.date}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="runsheet_id"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Runsheet Id(Optional)
                  </label>
                  <input
                    type="text"
                    id="runsheet_id"
                    name="runsheet_id"
                    value={formData.runsheet_id}
                    onChange={(e) => handleInputChange(e)}
                    onWheel={(e) => (e.target).blur()}
                    className={`block w-full bg-[#1F1F1F] border  rounded-md shadow-sm p-2 text-white`}
                  />
                </div>
              </>
            )}
            {/* Closing Form Fields */}
            {formData.status === "closing" && closingData && (
              <>
                <div>
                  <label
                    htmlFor="vehicle_name"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Vehicle Number
                  </label>
                  <select
                    id="vehicle_number"
                    name="vehicle_number"
                    value={selectedVehicleIdForClosingForm}
                    onChange={handleVehicleChangeForCloseForm} // Update selected vehicle
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vehicle</option>
                    {closingData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.vehicle_number.slice(0, -2) || "No Vehicle Number"} {/* Display vehicle number */}
                      </option>
                    ))}
                  </select>
                  {reviewErrors.vehicle_number && (
                    <p className="text-red-500">{reviewErrors.vehicle_number}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Date
                  </label>
                  <input
                    type="text"
                    id="date"
                    name="date"
                    value={
                      selectedVehicleDataForCloseForm?.date
                        ? new Date(selectedVehicleDataForCloseForm.date)
                          .toISOString()
                          .split("T")[0]
                        : ""
                    }
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                    readOnly // This will make the input read-only
                  />
                  {reviewErrors.date && (
                    <p className="text-red-500">{reviewErrors.date}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="runsheet_id"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Runsheet Id
                  </label>
                  <input
                    type="text"
                    id="runsheet_id"
                    name="runsheet_id"
                    value={selectedVehicleDataForCloseForm?.runsheet_id || formData.runsheet_id || ""}
                    onChange={(e) => handleInputChange(e)}
                    onWheel={(e) => (e.target).blur()}
                    className={`block w-full bg-[#1F1F1F] border  rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.runsheet_id && (
                    <p className="text-red-500">{reviewErrors.runsheet_id}</p>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )
      }
      {/* <button
        onClick={handleNextClick}
        className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
        // disabled={isLoading}
        disabled={!formData.status} // Disable if status is not selected
      >
        Next
      </button> */}
    </>
  );

  const renderStepTwo = () => (
    <>
      {error && <p className="text-yellow-500 mb-4">{error}</p>}
      <div className="space-y-4 mb-6">
        {/* Opening form remains unchanged */}
        {formData.status === "opening" && (
            <div>
              <label
                htmlFor="gps_device_number"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                GPS Device Number
              </label>
              <input
                type="text"
                id="gps_device_number"
                name="gps_device_number"
                value={hasGpsDevice ? '' : (formData.gps_device_number || selectedVehicleDataForCloseForm?.gps_device_number || '')}
                onChange={(e) => handleInputChange(e)}
                onWheel={(e) => (e.target).blur()}
                disabled={hasGpsDevice === true}
                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white ${hasGpsDevice === 'yes' ? 'opacity-50 cursor-not-allowed' : ''}`}
              />
              {reviewErrors.gps_device_number && (
                <p className="text-red-500">{reviewErrors.gps_device_number}</p>
              )}
            </div>
        )}

        {/* Modified closing form logic */}
        {formData.status === "closing" && closingData && selectedVehicleDataForCloseForm && (
          <>
            {/* Show GPS device number input if vehicle has GPS */}
            {selectedVehicleDataForCloseForm.gps_device_number && (
              <>
                <div>
                  <label
                    htmlFor="gps_device_number"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    GPS Device Number
                  </label>
                  <input
                    type="text"
                    id="gps_device_number"
                    name="gps_device_number"
                    value={formData.gps_device_number || selectedVehicleDataForCloseForm.gps_device_number}
                    onChange={(e) => handleInputChange(e)}
                    onWheel={(e) => (e.target).blur()}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  />
                  {reviewErrors.gps_device_number && (
                    <p className="text-red-500">{reviewErrors.gps_device_number}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="gps_km"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    GPS Kms
                  </label>
                  <input
                    type="number"
                    id="gps_km"
                    name="gps_km"
                    value={formData.gps_km || selectedVehicleDataForCloseForm.gps_km}
                    onChange={(e) => handleInputChange(e)}
                    onWheel={(e) => (e.target).blur()}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  />
                  {reviewErrors.gps_km && (
                    <p className="text-red-500">{reviewErrors.gps_km}</p>
                  )}
                </div>
              </>
            )}

            {/* Show image upload section if vehicle has no GPS */}
            {!selectedVehicleDataForCloseForm.gps_device_number && (
              <>
                <div>
                  <label
                    htmlFor="vehicle_image"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Upload Vehicle Number Plate Image
                  </label>
                  <Home onCapture={handleCapturedImage} />
                  {reviewErrors.vehicle_image && (
                    <p className="text-red-500">{reviewErrors.vehicle_image}</p>
                  )}
                </div>
                
                <div>
                  <label
                    htmlFor="odometer_image"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Upload Odometer Image
                  </label>
                  <Home onCapture={handleCapturedOdometerImage} />
                  {reviewErrors.odometer_image && (
                    <p className="text-red-500">{reviewErrors.odometer_image}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="closing_km"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Closing Kms
                  </label>
                  <input
                    type="number"
                    id="closing_km"
                    name="closing_km"
                    value={formData.closing_km}
                    onChange={(e) => handleInputChange(e)}
                    onWheel={(e) => (e.target).blur()}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  />
                  {reviewErrors.closing_km && (
                    <p className="text-red-500">{reviewErrors.closing_km}</p>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {formData.status === "opening" && (
          <div className="flex items-center space-x-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="gps_device"
                checked={hasGpsDevice === true}
                onChange={() => {
                  setHasGpsDevice((prev) => {
                    const newState = !prev;
                    if (newState) {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        gps_device_number: '',
                      }));
                    }
                    return newState;
                  });
                }}
                className="form-checkbox text-indigo-600"
              />
              <span className="ml-2 text-gray-300 mx-2">
                I don't have a GPS device number.
              </span>
            </label>
          </div>
        )}

        {formData.status === "opening" && hasGpsDevice === true && (
          <>
            <div>
              <label
                htmlFor="vehicle_image"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Upload Vehicle Number Plate Image
              </label>
              <Home onCapture={handleCapturedImage} />
              {reviewErrors.vehicle_image && (
                <p className="text-red-500">{reviewErrors.vehicle_image}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="odometer_image"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Upload Odometer Image
              </label>
              <Home onCapture={handleCapturedOdometerImage} />
              {reviewErrors.odometer_image && (
                <p className="text-red-500">{reviewErrors.odometer_image}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="opening_kms"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Opening Kms
              </label>
              <input
                type="number"
                id="opening_km"
                name="opening_km"
                value={formData.opening_km}
                onChange={(e) => handleInputChange(e)}
                onWheel={(e) => (e.target).blur()}
                className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
              />
              {reviewErrors.opening_km && (
                <p className="text-red-500">{reviewErrors.opening_km}</p>
              )}
            </div>
          </>
        )}
      </div>

      <button
        onClick={handleNextClick}
        className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
        disabled={!formData.status}
      >
        Next
      </button>

      {timelineItems.length > 0 && (
        <div className="mt-8" aria-live="polite">
          <h4 className="text-lg font-medium mb-4 text-gray-200">Progress</h4>
          <Timeline>
            {timelineItems.map((item, index) => (
              <TimelineItem key={index} title={item.title} status={item.status} />
            ))}
          </Timeline>
        </div>
      )}
    </>
);

  // const renderStepTwo = () => (
  //   <>
  //     {/* <h3 className="text-xl font-medium mb-4 text-gray-200">
  //       <div className="flex w-full justify-between items-center">
  //         <div className="flex flex-col">
  //           <p
  //             className="text-sm font-medium text-gray-100 opacity-65"
  //             style={{ fontSize: "0.75rem" }}
  //           >
  //             Step 2 of 2
  //           </p>
  //           Review Your Information
  //         </div>
  //         <p
  //           className={`text-[16px] cursor-pointer font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
  //           onClick={() => {
  //             setCurrentStep(1);
  //           }}
  //         >
  //           <ArrowLeft size={18} />
  //           Back
  //         </p>
  //       </div>
  //     </h3> */}
  //     {/* {isLoading ? (
  //       <p className="text-white">Loading user data...</p>
  //     ) : ( */}
  //     <>
  //       {error && <p className="text-yellow-500 mb-4">{error}</p>}
  //       <div className="space-y-4 mb-6">
  //         {formData.status === "opening" && (
  //           <div>
  //             <label
  //               htmlFor="gps_device_number"
  //               className="block text-sm font-medium text-gray-300 mb-1"
  //             >
  //               GPS Device Number
  //             </label>
  //             <input
  //               type="text"
  //               id="gps_device_number"
  //               name="gps_device_number"
  //               // Only show value if hasGpsDevice is false
  //               value={hasGpsDevice ? '' : (formData.gps_device_number || selectedVehicleDataForCloseForm?.gps_device_number || '')}
  //               // value={formData.gps_device_number || selectedVehicleDataForCloseForm?.gps_device_number}
  //               onChange={(e) => handleInputChange(e)}
  //               onWheel={(e) => (e.target).blur()}
  //               // className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
  //               disabled={hasGpsDevice === true}  // Disable if checkbox is selected
  //               className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white ${hasGpsDevice === 'yes' ? 'opacity-50 cursor-not-allowed' : ''
  //                 }`}  // Add visual feedback for disabled state
  //             />
  //             {reviewErrors.gps_device_number && (
  //               <p className="text-red-500">{reviewErrors.gps_device_number}</p>
  //             )}
  //           </div>
  //         )}
  //         {formData.status === "closing" && closingData && hasGpsDevice === false && selectedVehicleDataForCloseForm?.gps_device_number && (
  //           <div>
  //             <label
  //               htmlFor="gps_device_number"
  //               className="block text-sm font-medium text-gray-300 mb-1"
  //             >
  //               GPS Device Number
  //             </label>
  //             <input
  //               type="number"
  //               id="gps_device_number"
  //               name="gps_device_number"
  //               value={formData.gps_device_number || selectedVehicleDataForCloseForm?.gps_device_number}
  //               onChange={(e) => handleInputChange(e)}
  //               onWheel={(e) => (e.target).blur()}
  //               // className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
  //               disabled={hasGpsDevice === true}  // Disable if checkbox is selected
  //               className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white ${hasGpsDevice === 'yes' ? 'opacity-50 cursor-not-allowed' : ''
  //                 }`}  // Add visual feedback for disabled state
  //             />
  //             {reviewErrors.gps_device_number && (
  //               <p className="text-red-500">{reviewErrors.gps_device_number}</p>
  //             )}
  //           </div>
  //         )}
  //         {formData.status === "closing" && closingData && hasGpsDevice === false && selectedVehicleDataForCloseForm?.gps_device_number && (
  //           <div>
  //             <label
  //               htmlFor="gps_km"
  //               className="block text-sm font-medium text-gray-300 mb-1"
  //             >
  //               GPS Kms
  //             </label>
  //             <input
  //               type="number"
  //               id="gps_km"
  //               name="gps_km"
  //               value={formData.gps_km || selectedVehicleDataForCloseForm?.gps_km}
  //               onChange={(e) => handleInputChange(e)}
  //               onWheel={(e) => (e.target).blur()}
  //               className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
  //             />
  //             {reviewErrors.gps_km && (
  //               <p className="text-red-500">{reviewErrors.gps_km}</p>
  //             )}
  //           </div>
  //         )}
  //         {formData.status === "opening" ? (
  //           <div className="flex items-center space-x-4">
  //             <label className="inline-flex items-center">
  //               <input
  //                 type="checkbox"
  //                 name="gps_device"
  //                 checked={hasGpsDevice === true}
  //                 onChange={() => {
  //                   setHasGpsDevice((prev) => {
  //                     const newState = !prev; // Toggle state
  //                     if (newState) {
  //                       // Clear GPS device number if user selects 'yes'
  //                       setFormData((prevFormData) => ({
  //                         ...prevFormData,
  //                         gps_device_number: '', // Clear input
  //                       }));
  //                     }
  //                     return newState;
  //                   });
  //                 }}
  //                 className="form-checkbox text-indigo-600"
  //               />
  //               <span className="ml-2 text-gray-300 mx-2">
  //                 I don't have a GPS device number.
  //               </span>
  //             </label>
  //           </div>
  //         )
  //           : null}

  //         {/* Conditionally render GPS device input or Home component */}
  //         {/* For "opening" state, show hasGpsDevice */}
  //         {formData.status === "opening" && hasGpsDevice === true && (
  //           <>
  //             {/* Common Image Section */}
  //             <div>
  //               <label
  //                 htmlFor="vehicle_image"
  //                 className="block text-sm font-medium text-gray-300 mb-2"
  //               >
  //                 Upload Vehicle Number Plate Image
  //               </label>
  //               <Home onCapture={handleCapturedImage} />
  //               {reviewErrors.vehicle_image && (
  //                 <p className="text-red-500">{reviewErrors.vehicle_image}</p>
  //               )}
  //             </div>
  //             <div>
  //               <label
  //                 htmlFor="odometer_image"
  //                 className="block text-sm font-medium text-gray-300 mb-2"
  //               >
  //                 Upload Odometer Image
  //               </label>
  //               <Home onCapture={handleCapturedOdometerImage} />
  //               {reviewErrors.odometer_image && (
  //                 <p className="text-red-500">{reviewErrors.odometer_image}</p>
  //               )}
  //             </div>

  //             {/* Opening Kms */}
  //             <div>
  //               <label
  //                 htmlFor="opening_kms"
  //                 className="block text-sm font-medium text-gray-300 mb-1"
  //               >
  //                 Opening Kms
  //               </label>
  //               <input
  //                 type="number"
  //                 id="opening_km"
  //                 name="opening_km"
  //                 value={formData.opening_km}
  //                 onChange={(e) => handleInputChange(e)}
  //                 onWheel={(e) => (e.target).blur()}
  //                 className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
  //               />
  //               {reviewErrors.opening_km && (
  //                 <p className="text-red-500">{reviewErrors.opening_km}</p>
  //               )}
  //             </div>
  //           </>
  //         )}

  //         {/* For "closing" state, hide hasGpsDevice, only show camera and closing Kms */}
  //         {formData.status === "closing" && closingData && !selectedVehicleDataForCloseForm?.gps_device_number &&(
  //           <>
  //             {/* Common Image Section */}
  //             <div>
  //               <label
  //                 htmlFor="vehicle_image"
  //                 className="block text-sm font-medium text-gray-300 mb-2"
  //               >
  //                 Upload Vehicle Number Plate Image
  //               </label>
  //               <Home onCapture={handleCapturedImage} />
  //               {reviewErrors.vehicle_image && (
  //                 <p className="text-red-500">{reviewErrors.vehicle_image}</p>
  //               )}
  //             </div>
  //             <div>
  //               <label
  //                 htmlFor="odometer_image"
  //                 className="block text-sm font-medium text-gray-300 mb-2"
  //               >
  //                 Upload Odometer Image
  //               </label>
  //               <Home onCapture={handleCapturedOdometerImage} />
  //               {reviewErrors.odometer_image && (
  //                 <p className="text-red-500">{reviewErrors.odometer_image}</p>
  //               )}
  //             </div>

  //             {/* Closing Kms */}
  //             <div>
  //               <label
  //                 htmlFor="closing_km"
  //                 className="block text-sm font-medium text-gray-300 mb-1"
  //               >
  //                 Closing Kms
  //               </label>
  //               <input
  //                 type="number"
  //                 id="closing_km"
  //                 name="closing_km"
  //                 value={formData.closing_km}
  //                 onChange={(e) => handleInputChange(e)}
  //                 onWheel={(e) => (e.target).blur()}
  //                 className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
  //               />
  //               {reviewErrors.closing_km && (
  //                 <p className="text-red-500">{reviewErrors.closing_km}</p>
  //               )}
  //             </div>
  //           </>
  //         )}

  //       </div>
  //     </>
  //     {/* )} */}
  //     <button
  //       onClick={handleNextClick}
  //       className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
  //       // disabled={isLoading}
  //       disabled={!formData.status}
  //     >
  //       Next
  //     </button>

  //     {timelineItems.length > 0 && (
  //       <div className="mt-8" aria-live="polite">
  //         <h4 className="text-lg font-medium mb-4 text-gray-200">Progress</h4>
  //         <Timeline>
  //           {timelineItems.map((item, index) => (
  //             <TimelineItem key={index} title={item.title} status={item.status} />
  //           ))}
  //         </Timeline>
  //       </div>
  //     )}
  //   </>
  // );

  const renderStepThree = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col">
            <p
              className="text-sm font-medium text-gray-100 opacity-65"
              style={{ fontSize: "0.75rem" }}
            >
              Step 2 of 2
            </p>
            Review Your Information
          </div>
          <p
            className={`text-[16px] font-regular cursor-pointer text-gray-100 opacity-65 flex gap-1 items-center`}
            onClick={() => {
              setCurrentStep(1);
            }}
          >
            <ArrowLeft size={18} />
            Back
          </p>
        </div>
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : (
        <>
          {error && <p className="text-yellow-500 mb-4">{error}</p>}
          <div className="space-y-4 mb-6">
            {formData.status === "closing" && closingData && (
              <>
                <div>
                  <label
                    htmlFor="fresh_load"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Fresh Load (Optional)
                  </label>
                  <input
                    type="number"
                    id="fresh_load"
                    name="fresh_load"
                    value={formData?.fresh_load}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.fresh_load && (
                    <p className="text-red-500">{reviewErrors.fresh_load}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="out_for_delivery"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Out For Delivery (Optional)
                  </label>
                  <input
                    type="number"
                    id="out_for_delivery"
                    name="out_for_delivery"
                    value={formData?.out_for_delivery}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.out_for_delivery && (
                    <p className="text-red-500">{reviewErrors.out_for_delivery}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="delivered"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Delivered (Optional)
                  </label>
                  <input
                    type="number"
                    id="delivered"
                    name="delivered"
                    value={formData?.delivered}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.delivered && (
                    <p className="text-red-500">{reviewErrors.delivered}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="undelivered"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Undelivered (Optional)
                  </label>
                  <input
                    type="number"
                    id="undelivered"
                    name="undelivered"
                    value={formData?.undelivered}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.undelivered && (
                    <p className="text-red-500">{reviewErrors.undelivered}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="out_for_pickup"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Out For Pickup (Optional)
                  </label>
                  <input
                    type="number"
                    id="out_for_pickup"
                    name="out_for_pickup"
                    value={formData?.out_for_pickup}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.out_for_pickup && (
                    <p className="text-red-500">{reviewErrors.out_for_pickup}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="pickup_done"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    PickUp Done (Optional)
                  </label>
                  <input
                    type="number"
                    id="pickup_done"
                    name="pickup_done"
                    value={formData?.pickup_done}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.pickup_done && (
                    <p className="text-red-500">{reviewErrors.pickup_done}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="pickup_not_done"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    PickUp Not Done (Optional)
                  </label>
                  <input
                    type="number"
                    id="pickup_not_done"
                    name="pickup_not_done"
                    value={formData?.pickup_not_done}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.pickup_not_done && (
                    <p className="text-red-500">{reviewErrors.pickup_not_done}</p>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}{" "}
      <button
        onClick={handleNextClick}
        className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
        disabled={isLoading}
      >
        Next {/* Show loading text or indicator */}
      </button>
      {timelineItems.length > 0 && (
        <div className="mt-8" aria-live="polite">
          <h4 className="text-lg font-medium mb-4 text-gray-200">Progress</h4>
          <Timeline>
            {timelineItems.map((item, index) => (
              <TimelineItem key={index} title={item.title} status={item.status} />
            ))}
          </Timeline>
        </div>
      )}
    </>
  );

  const renderOnSuccess = () => (
    <div className="w-full h-[75vh] flex flex-col gap-28 pt-10 animate-slide-in-right">
      <div className="flex flex-col items-center gap-6">
        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
        <p className="text-2xl ">Completed</p>
      </div>
      <p className="text-white text-center">
        You have successfully submitted your Information
      </p>
    </div>
  );

  const handleFormDataFromModalSubmit = (data) => {
    console.log("Received form data from child:", data, reviewInfo.hub_name);
    const newVendor = {
      name: data.name,
      id: data.id,
    };

    // Update the vendor state by appending the new vendor
    setVendorName((prevVendors) => [...prevVendors, newVendor]);
    fetchDataForVendorIdAndVehicleId(reviewInfo.hub_name, 'adhoc');
    console.log("fetching........")
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
      <h2 className="text-[16px] font-medium mb-6 text-center text-white-400">
        DeliveryPlus - Vendor Vehicle Management
      </h2>

      {currentStep === 1 && renderReviewInformation()}
      {currentStep === 1 && renderStepTwo()}
      {/* {currentStep === 3 && renderStepThree()} */}
      {formData.status !== "opening" && currentStep === 2 && renderStepThree()}
      {(formData.status !== "opening" && currentStep === 3) ||
        (formData.status === "opening" && currentStep === 2)
        ? renderOnSuccess()
        : null}
      {/* {formData.status !== "opening" && currentStep === 3 && renderOnSuccess()} */}

      {currentStep !== 4 && (
        <button
          onClick={resetForm}
          className="w-full mt-4 px-4 py-2 text-gray-400 hover:text-red-300 flex items-center justify-center border border-gray-400 rounded-md transition-colors"
        >
          <XCircleIcon className="w-5 h-5 mr-1" />
          Reset
        </button>
      )}
      <CustomSelectInput
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        handleRightClick={handleRightClick}
      />
      < AddAdhocDetails
        isOpen={isVendorDetailOpen}
        close={() => setIsVendorDetailOpen(false)}
        hubId={reviewInfo.hub_name}
        onSubmit={handleFormDataFromModalSubmit}
      />
    </div>
  );
};

export default VendorVehicle;
