export const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const getBase64FromDataURL = (dataURL) => {
  return dataURL.split(",")[1];
};

export const initialFormState = {
  amount: "",
  employee_id: "",
  hub: "",
  account: "",
  payment_method: "",
  date: "",
  image: null,
  form_remarks: "",
  slip_number: "",
  overall_pending_amount: "",
};


export const getLastThreeDays = () => {
  const today = new Date();
  const yesterday = new Date(today);
  const dayBeforeYesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1);
  dayBeforeYesterday.setDate(today.getDate() - 2);

  return [
    today.toISOString().split("T")[0],
    yesterday.toISOString().split("T")[0],
    dayBeforeYesterday.toISOString().split("T")[0],
  ];
};


